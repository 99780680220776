export const environment = {
  url: 'https://api.develop.inventoryvolt.com/api/v2',
  // url: 'http://0.0.0.0:8001/api/v2',
  production: false,
  GOOGLE_MAPS_URL: 'https://maps.googleapis.com/maps/api/geocode/json',
  MAPS_KEY: 'AIzaSyCEXnc8bIg6gz6UHVeT0HsDHqiCNKoby3c',
  INSPECTION_VIEWER_EMAIL: 'inspection-viewer@rentahaus.co.uk',
  DO_ACCESS_KEY: 'ZR577FVHGPWDKEGU3VHQ',
  DO_SECRET_KEY: 'kkEorrxHPUXLvI6OA3DWT48O6sawo/uCast5x5KqNZY',
  SENTRY_DSN: 'https://77d6e37f39d24e229fc8508c4a68c512@o1001270.ingest.sentry.io/5962633',
  FE_URL: 'localhost',
  BE_URL: 'https://api.develop.inventoryvolt.com/api/v2',
  INV_URL: 'https://inventoryvolt.com'
};

